<template>
    <div class="CarDriverAppointView">
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <van-list
            v-model="list_loading"
            :finished="finished"
            finished-text="我是有底线的"
            @load="onLoad"
        >
            <van-cell-group inset v-for="v in list" :key="v.name" @click="show_details(v)">
                <van-cell>
                    <template #title>
                        <span class="title_span">{{v.car_number}}</span>
                    </template>
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="v.state===0">待分配</van-tag>
                        <van-tag plain type="primary" v-else-if="v.state===1">已分配</van-tag>
                        <van-tag plain type="warning" v-else-if="v.state===2">装卸中</van-tag>
                        <van-tag plain type="success" v-else-if="v.state===3">已完成</van-tag>
                        <van-tag plain type="danger" v-else-if="v.state===4">已取消</van-tag>
                        <van-tag plain type="danger" v-else-if="v.state===5">已过期</van-tag>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="登记类型" :value="v.car_business_type" :border="false" />
                <van-cell class="note_cell" title="车俩类型" :value="v.car_size_type" :border="false" />
                <van-cell v-if="v.box_num" class="note_cell" title="集装箱号" :value="v.box_num" :border="false" />
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'

import { car_driver_appoint_index_request } from '@/network/CarDriverAppoint'

export default {
    name:'CarDriverCarView',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            my_nav_bar_name: '历史登记记录',
            list: [],
            total: 0,
            list_loading: false,
            finished: false,
            form_key: Date.now(),
            cond: {
                page: 1
            }
        }
    },
    computed:{},
    methods:{
        get_car_driver_appoint() {
            this.list = []
            this.finished = false
            this.$store.commit('true_loading')
            car_driver_appoint_index_request(this.cond)
                .then((s) => {
                    if (s.status === 0) {
                        this.list = s.result.data
                        this.total =s.reuult.total
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    // this.$toast.fail(err);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        show_details(v) {
            this.$router.push({
                name: 'CarDriverAppointDetails',
                query: {
                    id: v.id
                }
            })
        },
        onLoad() {
            setTimeout(() => { 
                this.cond.page++
                car_driver_appoint_index_request(this.cond)
                    .then((s) => {
                        if (s.status === 0) {
                            this.total = s.result.total
                            s.result.data.forEach(v => {
                                this.list.push(v)
                            });
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => {
                        // 加载状态结束
                        this.list_loading = false;

                        // 数据全部加载完成
                        if (this.list.length >= this.total) {
                            this.finished = true;
                        }
                    })
            }, 500);
        },
    },
    filters:{},
    props:{},
    created(){
        this.get_car_driver_appoint()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent
    },
    watch:{}
}
</script>

<style>
.CarDriverAppointView .van-cell-group {
    margin-bottom: 0.2rem;
}
.CarDriverAppointView .title_span {
    font-weight: bold;
    color: #0080FF;
}
.CarDriverAppointView .note_cell {
    padding: 0.1rem 0.42667rem
}
.CarDriverAppointView .insert {
    text-align: center;
    /* position: absolute; */
        transform: translateY(-40%);
}
</style>